<template>
    <div :class="$style['analytics-item']">
        <div :class="$style['title']">
            <slot name="title"/>
        </div>

        <div :class="$style['value']">
            <slot
                key="value"
                name="value"
            />

            <div
                v-if="$slots['value-sup']"
                key="sup"
                :class="$style['value-sup']"
            >
                <slot name="value-sup"/>
            </div>

            <slot name="after-value"/>
        </div>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .analytics-item
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 8px
        padding: 12px 16px
        border: 1px solid #e3e5eb
        border-radius: 16px

        .title
            font-size: 16px
            font-weight: 500
            line-height: 130%
            color: #8a8f9e

        .value
            display: flex
            gap: 8px
            width: 100%
            font-size: 28px
            font-weight: 500
            color: #000

            &-sup
                padding-top: 3px
                font-size: 14px
                font-weight: 500
</style>
