<script
    lang="ts"
    setup
>
    import type { AppSegmentControlItem } from '~/ts/types/app'

    type Props = {
        modelValue: any
        items: AppSegmentControlItem[]
        disabled?: boolean
        disableTooltips?: boolean
        itemClass?: string
        itemWidth?: string | number
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
    }

    const props = withDefaults(defineProps<Props>(), {
        disabled: false,
        disableTooltips: false,
        itemClass: undefined,
        itemWidth: 125
    })
    const emit = defineEmits<Emit>()

    const inputModel = useSyncProp(props, 'modelValue', emit)

    const itemWidthValue = computed<string>(() => getSizeValue(props.itemWidth))

    const style = useCssModule()

    const getItemClass = (item: AppSegmentControlItem): string[] => {
        const classes = [ style['item'] ]

        if (props.itemClass) {
            classes.push(props.itemClass)
        }

        if (inputModel.value === item.value) {
            classes.push(style['active'])
        }

        if (props.disabled || item.disabled) {
            classes.push(style['disabled'])
        }

        return classes
    }
</script>

<template>
    <div :class="$style['segment-control']">
        <template
            v-for="item in props.items"
            :key="item.value"
        >
            <AppTooltip
                v-bind="item.tooltipArgs"
                :disabled="props.disableTooltips || item.tooltipArgs?.disabled || !item.tooltipText"
            >
                <template #activator="{ open, close }">
                    <button
                        type="button"
                        :class="getItemClass(item)"
                        :disabled="props.disabled || item.disabled"
                        @mouseenter.passive="open"
                        @mouseleave.passive="close"
                        @click="inputModel = item.value"
                    >
                        <slot
                            :name="'item:' + item.value"
                            :item="item"
                        >
                            {{ item.text }}
                        </slot>
                    </button>
                </template>

                {{ item.tooltipText }}
            </AppTooltip>
        </template>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .segment-control
        display: flex
        gap: 4px
        height: 36px
        padding: 2px
        background: #ebeef1
        border-radius: 10px

        .item
            --bg: inherit

            &.active
                --bg: #fff

            &.disabled
                color: #a7a7a7
                cursor: not-allowed

            &:not(.active):not(.disabled)
                &:hover
                    --bg: #e3e5eb

                &:active
                    --bg: #ced2dc

            appearance: none
            display: flex
            justify-content: center
            align-items: center
            gap: 8px
            width: v-bind('itemWidthValue')
            padding: 8px 16px
            font-size: 14px
            font-weight: 500
            background: var(--bg)
            outline: none
            border: none
            text-align: inherit
            border-radius: 8px
            transition: background-color var(--transition-default-duration-with-ease)
            will-change: background-color
            cursor: pointer
</style>
